import { Injectable } from '@angular/core';
import { BaseCoreService } from './base-core.service';
import { HttpClient } from '@angular/common/http';
import { Order } from '../interfaces/order.interface';
import { Pagination } from '../interfaces/pagination.interface';
import { Observable } from 'rxjs';
import { Bill } from 'app/modules/bill/models/bill.model';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root'
})
export class BillCoreService extends BaseCoreService<Bill> {
    constructor(_httpClient: HttpClient, _userService: UserService) {
        super('v1/bill', _httpClient, _userService);
    }

    findIn(
        filter: Bill,
        page: number = 1,
        limit: number = 10000,
        order: Order = { column: 'number', typeOrder: 'ASC' }
    ): Observable<Pagination<Bill>> {
        return super.findIn(filter, page, limit, order);
    }
}
