import { Directive, ElementRef, HostListener, inject, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormatUtils } from '../utils/format.util';
import { Clipboard } from '@angular/cdk/clipboard';

@Directive({
    selector: 'input[inputDecimal], input[input-decimal]',
    standalone: true,
    exportAs: 'inputDecimal',
})
export class InputDecimalDirective implements OnInit {
    clipboard = inject(Clipboard);
    formatUtils = inject(FormatUtils);
    ngControl = inject(NgControl);
    el = inject<ElementRef<HTMLInputElement>>(ElementRef);
    input = this.el.nativeElement;
    /**
     * Used to store the next clean position in the middle of keydown and ngModelChange events
     * so that we can set the correct cursor position in the input. If it is null, we don't need
     * to update the cursor position.
     */
    private _nextCleanPosition: number | null = null;

    ngOnInit(): void {
        this.handleNgModelChange(this.ngControl.control.value);
    }

    /** Parse text and setSelectedRange in input after ngModelChange */
    @HostListener('ngModelChange', ['$event'])
    handleNgModelChange(event: number) {
        if (typeof event !== 'number' || isNaN(event)) {
            this._inputValue = '';
        } else {
            this._inputValue = this.formatUtils.formatAmount(event);
        }
        if(this._nextCleanPosition !== null) {
            const pos = this._convertCleanPositionToRaw(this._inputValue, this._nextCleanPosition);
            this.input.setSelectionRange(pos, pos);
            this._nextCleanPosition = null;
        }
    }

    @HostListener('keydown', ['$event'])
    handleKeydown(event: KeyboardEvent) {
        if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'End', 'Home', 'Tab'].includes(event.key)) {
            return;
        }
        // Avoid preventDefault while copying or pasting
        if (['v', 'c', 'x'].includes(event.key) && (event.ctrlKey || event.metaKey)) return;

        event.preventDefault();
        if (event.key === 'Backspace') {
            const [start, end] = this._getRange();
            const decimalPosition = this._inputValue.indexOf(',');
            if (start === end && start === decimalPosition + 1) {
                this.input.setSelectionRange(decimalPosition, decimalPosition);
                return;
            }

            const [startClean, endClean] = this._getCleanRange();
            let numberValue = this._inputCleanValue;

            if (start === end && start > decimalPosition + 1) {
                const left = numberValue.slice(0, startClean - 1);
                const right = numberValue.slice(startClean);
                numberValue = `${left}0${right}`;
                this._nextCleanPosition = startClean - 1;
            } else if (startClean === endClean) {
                // Clear closest left chart
                if ((this._inputValue === '0,00' && startClean === 1) || (this._inputValue === '-0,00' && startClean === 2)) {
                    numberValue = '';
                    this._nextCleanPosition = 0;
                } else if(startClean === 0) {
                    this._nextCleanPosition = 0;
                } else {
                    const left = numberValue.slice(0, startClean - 1);
                    const right = numberValue.slice(startClean);
                    numberValue = left + right;
                    this._nextCleanPosition = startClean - 1;
                }
            } else {
                // Clear range
                const left = numberValue.slice(0, startClean);
                const right = numberValue.slice(endClean);
                numberValue = left + right;
                this._nextCleanPosition = startClean;
            }

            this.ngControl.control.setValue(numberValue === '' ? null : Number(numberValue));
        } else if(event.key === 'Delete') {
            const [start, end] = this._getRange();
            const decimalPosition = this._inputValue.indexOf(',');

            // Si el cursor está justo antes de la coma decimal, mueve el cursor después de la coma y evita borrar
            if (start === end && start === decimalPosition) {
                this.input.setSelectionRange(decimalPosition + 1, decimalPosition + 1);
                return;
            }
            const [startClean, endClean] = this._getCleanRange();
            let numberValue = this._inputCleanValue;

            if (start === end && start > decimalPosition) {
                const left = numberValue.slice(0, startClean);
                const right = numberValue.slice(startClean + 1);
                numberValue = `${left}0${right}`;
                this._nextCleanPosition = startClean + 1;
            } else if (startClean === endClean) {
                // Clear closest right character
                if ((this._inputValue === '0,00' && startClean === 0) || (this._inputValue === '-0,00' && startClean === 1)) {
                    numberValue = '';
                    this._nextCleanPosition = 0;
                } else if (startClean >= numberValue.length) {
                    this._nextCleanPosition = startClean;
                } else {
                    const left = numberValue.slice(0, startClean);
                    const right = numberValue.slice(startClean + 1);
                    numberValue = left + right;
                    this._nextCleanPosition = startClean;
                }
            } else {
                // Clear range
                const left = numberValue.slice(0, startClean);
                const right = numberValue.slice(endClean);
                numberValue = left + right;
                this._nextCleanPosition = startClean;
            }

            this.ngControl.control.setValue(numberValue === '' ? null : Number(numberValue));
        } else if(event.code === 'Period') { // Period => . of normal keyboard (not numpad)
            const [start, end] = this._getRange();
            if (this._inputValue[start] === '.' && (start === end || start === end - 1)) {
                this.input.setSelectionRange(start + 1, start + 1);
            }
        } else if(event.key === '-') {
            const [start, end] = this._getRange();
            if (start !== 0) return;
            if (this._inputValue === '-0,00') return;
            if (this._inputValue === '') {
                this._inputValue = '-';
                this.input.setSelectionRange(1, 1);
                return;
            } else if (start === 0 && this._inputValue === '0,00') {
                this._inputValue = '-0,00';
                this.input.setSelectionRange(1, 1);
                return;
            } else if (this._inputValue !== '-' && (!this._ngValue || (this._ngValue > 0 && start === 0) || (this._ngValue < 0 && (start === 0 && end === 1)))) {
                const numberValue = this._inputCleanValue
                const [left, right] = [numberValue.slice(0, start), numberValue.slice(end)];
                const newValue = `-${right}`;
                this._nextCleanPosition = 1;
                this.ngControl.control.setValue(Number(newValue));
            }
        } else if(event.code === 'NumpadDecimal' || event.key === ',') { // NumpadDecimal => , of numpad
            const [start, end] = this._getRange();
            const decimalPosition = this._inputValue.indexOf(',');
            if (start === decimalPosition && (start === end || start === end - 1)) {
                this.input.setSelectionRange(start + 1, start + 1);
            } else if (start !== end && decimalPosition >= start && decimalPosition <= end) {
                let numberValue = this._inputCleanValue
                const [startClean, endClean] = this._getCleanRange();
                const [left, right] = [numberValue.slice(0, startClean), numberValue.slice(endClean)];
                numberValue = `${left ?? 0}.${right ?? 0}`;
                this._nextCleanPosition = startClean === 0 ? 2 : startClean + 1;
                this.ngControl.control.setValue(Number(numberValue));
            }
        } else if(/[0-9]/.test(event.key)) {
            const [start, end] = this._getRange();
            const decimalPosition = this._inputValue.indexOf(',');
            if (start === end && start === decimalPosition + 3) {
                return;
            }
            if (start === end && start > decimalPosition) {
                // We change selection to replace number after comma insted of moving the number to right
                this.input.setSelectionRange(start, end + (start - decimalPosition));
            }

            const numberValue = this._inputCleanValue
            const [startClean, endClean] = this._getCleanRange();
            const [left, right] = [numberValue.slice(0, startClean), numberValue.slice(endClean)];
            this._nextCleanPosition = startClean + 1;
            this.ngControl.control.setValue(Number(`${left}${event.key}${right}`));
        }
    }

    /** Use only to remove minus sign from input in case integer part is zero. For example: -0,10 => 0,10 */
    @HostListener('blur', ['$event'])
    handleBlur(): void {
        this._inputValue = this.formatUtils.formatAmount(this._ngValue);
    }

    @HostListener('cut', ['$event'])
    handleCut(event: ClipboardEvent) {
        const [start, end] = this._getRange();
        this.clipboard.copy(this._inputValue.slice(start, end));
        this.handleKeydown({ key: 'Backspace', preventDefault: () => {} } as KeyboardEvent);
    }

    @HostListener('paste', ['$event'])
    handlePaste(event: ClipboardEvent) {
        event.preventDefault();
        this._handlePaste(event.clipboardData?.getData('text/plain'));
    }

    private _handlePaste(text: string) {
        console.log(text);
        const cleanText = text.replace(/\./g, '').replace(',', '.');
        if (this._inputValue === '') {
            if (/^\-?\d+(\.\d{0,2})?$/.test(cleanText)) {
                this.ngControl.control.setValue(Number(cleanText));
            }
        } else {
            // We allow only integer pasting
            if (!/^\-?\d+$/.test(cleanText)) return;

            const [startClean, endClean] = this._getCleanRange();

            // If it is negative check we've got the cursor at the beginning
            if (/^\-\d+$/.test(cleanText) && (startClean !== 0 || this._inputValue.startsWith('-'))) return;

            let numberValue = this._inputCleanValue;
            const [left, right] = [numberValue.slice(0, startClean), numberValue.slice(endClean)];
            numberValue = `${left}${cleanText}${right}`;
            this._nextCleanPosition = startClean + cleanText.length;
            this.ngControl.control.setValue(Number(numberValue));
        }
    }

    private get _ngValue() {
        return this.ngControl.control.value;
    }

    private get _inputCleanValue() {
        return this.input.value.replace(/\./g, '').replace(',', '.');
    }

    private get _inputValue() {
        return this.input.value;
    }

    private set _inputValue(value: string) {
        this.input.value = value;
    }

    /** @returns cursor positions */
    private _getRange() {
        return [this.input.selectionStart, this.input.selectionEnd];
    }

    /** @returns cursor positions without considering thousands separator */
    private _getCleanRange() {
        const [start, end] = this._getRange();
        // Chars that appear before the position, for example -1.111.1|11,00 => There are 2 points and 1 minus before cursor => 3 chars
        const startAditionalChars = [...this._inputValue.slice(0, start)].reduce((acc, cur) => acc + (/\./.test(cur) ? 1 : 0), 0);
        const endAditionalChars = [...this._inputValue.slice(0, end)].reduce((acc, cur) => acc + (/\./.test(cur) ? 1 : 0), 0);
        return [
            this.input.selectionStart - startAditionalChars,
            this.input.selectionEnd - endAditionalChars,
        ];
    }

    /**
     * Convert a clean position (without thousands separator) to raw position (with thousands separator).
     * @param {string} number - The input value as a string Ex: '1.234,55'.
     * @param {number} cleanPosition - The clean position of the cursor without considering thousands separator.
     * @return {number} raw cursor position considering thousands separator.
     */
    private _convertCleanPositionToRaw(number: string, cleanPosition: number): number {
        let rawPosition = 0;
        let cleanCount = 0;
        // Iterate over each character in the string to find the raw position
        for (let i = 0; i < number.length; i++) {
            const char = number[i];
            // Only consider numbers, decimal separator and the negative sign
            if (/[0-9\-,]/.test(char)) cleanCount++;
            // When cleanCount matches cleanPosition, we have found the raw position
            if (cleanCount === cleanPosition) {
                rawPosition = i + 1; // +1 to adjust for zero-based index
                break;
            }
        }
        return rawPosition;
    }
}
